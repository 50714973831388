@import url('variables.css');
@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
.content-collapse .ant-collapse-header-text {
  font-family: var(--font-stack);
  color: var(--color-primary);
  font-weight: var(--primary-weight);
  font-size: 15px;
}

.content-panel .ant-collapse-expand-icon {
  font-family: var(--font-stack);
  color: var(--color-primary);
  font-weight: var(--primary-weight);
}
.content-display-one{
  display: none;
}
.content-display-two{
  display: block;
}
/* MOBILE */
@media (max-width: 768px) {
  .content-display-one{
    display: block;
  }
  .content-display-two{
    display: none;
  }
}
