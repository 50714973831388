/*
.row-margin {
    margin-top: 5px;
}

.primera-angostura-button {
    height: 50px;
    width: 45px;
    background-color: #434343;
    position: fixed;
    top: 10%;
    left: 0%;
    z-index: 10;
    border-radius: 0px 10px 10px 0px;
    opacity: 1;
    padding: 5px 0px;
}

.primera-angostura-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.primera-angostura-text {
    color: #FFFFFF;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 15px;
    display: none;
}

.primera-angostura-icon {
    width: 30px;
    height: auto;
    margin-top: 8px;
}

.print-button {
    height: 50px;
    width: 45px;
    background-color: #FFCD00;
    position: fixed;
    top: calc(10% + 60px);
    left: 0%;
    z-index: 10;
    border-radius: 0px 10px 10px 0px;
    opacity: 1;
    padding: 5px 0px;
}

.print-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.print-icon {
    width: 26px;
    height: auto;
    margin-top: 8px;
}

.print-text {
    color: #434343;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 15px;
    display: none;
}

.pay-button {
    height: 50px;
    width: 45px;
    background-color: #0885C7;
    position: fixed;
    top: calc(10% + 120px);
    left: 0%;
    z-index: 10;
    border-radius: 0px 10px 10px 0px;
    opacity: 1;
    padding: 5px 0px;
}

.pay-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.pay-text {
    color: #FFFFFF;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 15px;
    display: none;
}

.pay-icon {
    width: 30px;
    height: auto;
    margin-top: 5px;
}

@media (min-width: 576px) {
    .row-margin {
        margin-top: 5px;
    }

    .primera-angostura-button {
        height: 50px;
        width: 45px;
        background-color: #434343;
        position: fixed;
        top: 10%;
        left: 0%;
        z-index: 10;
        border-radius: 0px 10px 10px 0px;
        opacity: 1;
        padding: 5px 0px;
    }

    .primera-angostura-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .primera-angostura-text {
        color: #FFFFFF;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 15px;
        display: none;
    }

    .primera-angostura-icon {
        width: 30px;
        height: auto;
        margin-top: 8px;
    }

    .print-button {
        height: 50px;
        width: 45px;
        background-color: #FFCD00;
        position: fixed;
        top: calc(10% + 60px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 10px 10px 0px;
        opacity: 1;
        padding: 5px 0px;
    }

    .print-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .print-icon {
        width: 26px;
        height: auto;
        margin-top: 8px;
    }

    .print-text {
        color: #434343;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 15px;
        display: none;
    }

    .pay-button {
        height: 50px;
        width: 45px;
        background-color: #0885C7;
        position: fixed;
        top: calc(10% + 120px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 10px 10px 0px;
        opacity: 1;
        padding: 5px 0px;
    }

    .pay-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .pay-text {
        color: #FFFFFF;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 800;
        line-height: 15px;
        display: none;
    }

    .pay-icon {
        width: 30px;
        height: auto;
        margin-top: 5px;
    }
}

@media (min-width: 768px) {
    .row-margin {
        margin-top: 5px;
    }

    .primera-angostura-button {
        height: 90px;
        width: 60px;
        background-color: #434343;
        position: fixed;
        top: 20%;
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }

    .primera-angostura-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .primera-angostura-text {
        color: #FFFFFF;
        font-size: 9px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 12px;
        display: block;
    }

    .primera-angostura-icon {
        width: 28px;
        height: auto;
        margin-top: -6px;
    }

    .print-button {
        height: 90px;
        width: 60px;
        background-color: #FFCD00;
        position: fixed;
        top: calc(20% + 100px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }

    .print-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .print-text {
        color: #434343;
        font-size: 10px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }

    .print-icon {
        width: 28px;
        height: auto;
        margin-top: 0px;
    }

    .pay-button {
        height: 90px;
        width: 60px;
        background-color: #0885C7;
        position: fixed;
        top: calc(20% + 200px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 10px 0px;
    }

    .pay-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .pay-text {
        color: #FFFFFF;
        font-size: 11px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }

    .pay-icon {
        width: 30px;
        height: auto;
        margin-top: 4px;
    }
}

@media (min-width: 992px) {
    .row-margin {
        margin-top: 5px;
    }

    .primera-angostura-button {
        height: 100px;
        width: 70px;
        background-color: #434343;
        position: fixed;
        top: 20%;
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }

    .primera-angostura-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .primera-angostura-text {
        color: #FFFFFF;
        font-size: 10px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }

    .primera-angostura-icon {
        width: 32px;
        height: auto;
        margin-top: 0px;
    }

    .print-button {
        height: 100px;
        width: 70px;
        background-color: #FFCD00;
        position: fixed;
        top: calc(20% + 110px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }

    .print-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .print-text {
        color: #434343;
        font-size: 11px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }

    .print-icon {
        width: 32px;
        height: auto;
        margin-top: 2px;
    }

    .pay-button {
        height: 100px;
        width: 70px;
        background-color: #0885C7;
        position: fixed;
        top: calc(20% + 220px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 10px 0px;
    }

    .pay-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .pay-text {
        color: #FFFFFF;
        font-size: 11px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }

    .pay-icon {
        width: 34px;
        height: auto;
        margin-top: 4px;
    }
}

@media (min-width: 1200px) {
    .row-margin {
        margin-top: 5px;
    }

    .primera-angostura-button {
        height: 120px;
        width: 80px;
        background-color: #434343;
        position: fixed;
        top: 20%;
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }

    .primera-angostura-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .primera-angostura-text {
        color: #FFFFFF;
        font-size: 10px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }

    .primera-angostura-icon {
        width: 40px;
        height: auto;
        margin-top: 5px;
    }

    .print-button {
        height: 120px;
        width: 80px;
        background-color: #FFCD00;
        position: fixed;
        top: calc(20% + 130px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }

    .print-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .print-text {
        color: #434343;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }

    .print-icon {
        width: 40px;
        height: auto;
        margin-top: 5px;
    }

    .pay-button {
        height: 120px;
        width: 80px;
        background-color: #0885C7;
        position: fixed;
        top: calc(20% + 260px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 10px 0px;
    }

    .pay-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .pay-text {
        color: #FFFFFF;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }

    .pay-icon {
        width: 42px;
        height: auto;
        margin-top: 8px;
    }
}

@media (min-width: 1400px) {
    .row-margin {
        margin-top: 5px;
    }

    .primera-angostura-button {
        height: 120px;
        width: 80px;
        background-color: #434343;
        position: fixed;
        top: 20%;
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }

    .primera-angostura-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .primera-angostura-text {
        color: #FFFFFF;
        font-size: 10px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }

    .primera-angostura-icon {
        width: 40px;
        height: auto;
        margin-top: 5px;
    }

    .print-button {
        height: 120px;
        width: 80px;
        background-color: #FFCD00;
        position: fixed;
        top: calc(20% + 130px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }

    .print-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .print-text {
        color: #434343;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }

    .print-icon {
        width: 40px;
        height: auto;
        margin-top: 5px;
    }

    .pay-button {
        height: 120px;
        width: 80px;
        background-color: #0885C7;
        position: fixed;
        top: calc(20% + 260px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 10px 0px;
    }

    .pay-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .pay-text {
        color: #FFFFFF;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }

    .pay-icon {
        width: 42px;
        height: auto;
        margin-top: 8px;
    }
}

@media (min-width: 1600px) {
    .row-margin {
        margin-top: 5px;
    }

    .primera-angostura-button {
        height: 130px;
        width: 100px;
        background-color: #434343;
        position: fixed;
        top: 20%;
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }

    .primera-angostura-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .primera-angostura-text {
        color: #FFFFFF;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }

    .primera-angostura-icon {
        width: 45px;
        height: auto;
        margin-top: 5px;
    }

    .print-button {
        height: 130px;
        width: 100px;
        background-color: #FFCD00;
        position: fixed;
        top: calc(20% + 140px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 15px 0px;
    }

    .print-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .print-text {
        color: #434343;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }

    .print-icon {
        width: 44px;
        height: auto;
        margin-top: 5px;
    }

    .pay-button {
        height: 130px;
        width: 100px;
        background-color: #0885C7;
        position: fixed;
        top: calc(20% + 280px);
        left: 0%;
        z-index: 10;
        border-radius: 0px 20px 20px 0px;
        opacity: 1;
        padding: 10px 0px;
    }

    .pay-button:hover {
        opacity: 0.9;
        cursor: pointer;
    }

    .pay-text {
        color: #FFFFFF;
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 15px;
        display: block;
    }

    .pay-icon {
        width: 48px;
        height: auto;
        margin-top: 8px;
    }
} */

.row-margin {
    margin-top: 5px;
}

.primera-angostura-button,
.print-button,
.pay-button {
    height: 50px;
    width: 45px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 10px;
    opacity: 1;
    padding: 5px 0px;
    z-index: 10;
    display: flex;

    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.primera-angostura-button {
    background-color: #434343;
}

.primera-angostura-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.primera-angostura-icon {
    width: 30px;
    height: auto;
}

.primera-angostura-text {
    color: #FFFFFF;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 15px;
    margin-top: 5px;
    display: none; /* Oculto por defecto */
}

.print-button {
    background-color: #FFCD00;
    right: 65px; /* Espacio para separar los botones */

}

.print-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.print-icon {
    width: 26px;
    height: auto;
}

.print-text {
    color: #434343;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 15px;
    margin-top: 5px;
    display: none; /* Oculto por defecto */
}

.pay-button {
    background-color: #0885C7;
    right: 120px; /* Espacio para separar los botones */
}

.pay-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.pay-icon {
    width: 30px;
    height: auto;
}

.pay-text {
    color: #FFFFFF;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 15px;
    margin-top: 5px;
    display: none; /* Oculto por defecto */
}

/* Media Queries */
@media (min-width: 576px) {
    .primera-angostura-button,
    .print-button,
    .pay-button {
        bottom: 20px;
        right: 20px;
    }

    .print-button {
        right: 75px;
    }

    .pay-button {
        right: 130px;
    }
}

@media (min-width: 768px) {
    .primera-angostura-button,
    .print-button,
    .pay-button {
        height: 60px;
        width: 50px;
        bottom: 30px;
        right: 30px;
    }

    .print-button {
        right: 90px;
    }

    .pay-button {
        right: 150px;
    }

    .primera-angostura-text,
    .print-text,
    .pay-text {
        display: block; /* Mostrar el texto en pantallas más grandes */
    }
}

@media (min-width: 992px) {
    .primera-angostura-button,
    .print-button,
    .pay-button {
        height: 70px;
        width: 60px;
        bottom: 40px;
        right: 40px;
    }

    .print-button {
        right: 110px;
    }

    .pay-button {
        right: 180px;
    }

    .primera-angostura-icon {
        width: 32px;
    }

    .print-icon {
        width: 28px;
    }

    .pay-icon {
        width: 34px;
    }
}

@media (min-width: 1200px) {
    .primera-angostura-button,
    .print-button,
    .pay-button {
        height: 80px;
        width: 70px;
        bottom: 50px;
        right: 50px;
    }

    .print-button {
        right: 130px;
    }

    .pay-button {
        right: 210px;
    }

    .primera-angostura-icon {
        width: 36px;
    }

    .print-icon {
        width: 32px;
    }

    .pay-icon {
        width: 38px;
    }
}

@media (min-width: 1400px) {
    .primera-angostura-button,
    .print-button,
    .pay-button {
        height: 90px;
        width: 80px;
        bottom: 60px;
        right: 60px;
    }

    .print-button {
        right: 150px;
    }

    .pay-button {
        right: 240px;
    }

    .primera-angostura-icon {
        width: 40px;
    }

    .print-icon {
        width: 36px;
    }

    .pay-icon {
        width: 42px;
    }
}

@media (min-width: 1600px) {
    .primera-angostura-button,
    .print-button,
    .pay-button {
        height: 100px;
        width: 90px;
        bottom: 70px;
        right: 70px;
    }

    .print-button {
        right: 170px;
    }

    .pay-button {
        right: 270px;
    }

    .primera-angostura-icon {
        width: 44px;
    }

    .print-icon {
        width: 40px;
    }

    .pay-icon {
        width: 46px;
    }
}

/* //otra forma estatico */
.fixed-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin: 10px;
}

.first-button, .second-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 67px;
    padding: 10px;
    border-radius: 13px;
    opacity: 1;
}

.first-button {
    background-color: #434343;
}

.second-button {
    background-color: #FFCD00;
}

.first-button:hover, .second-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.icon {
    width: 30px;
    height: auto;
    margin-right: 10px;
    margin-left: 10px; /* Separar el icono de la izquierda en pantallas grandes */
}

.button-text {
    color: #FFFFFF;
    font-size: 12px;
    font-family: 'Lato';
    font-weight: 800;
    line-height: 15px;
    text-align: left;
    margin-top: 12px;
    margin-left: 10px;
}

@media (max-width: 768px) {
    .button-text {
        display: none;
    }

    .first-button, .second-button {
        width: 67px;
        height: 67px;
        border-radius: 50%;
        justify-content: center;
        padding: 0;
    }

    .icon {
        margin: 0; /* Centramos el icono en pantallas pequeñas */
    }
}