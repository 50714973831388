.custom-floating-label-input {
  position: relative;
}

.custom-floating-label-input label {
  position: absolute;
  top: 15%; 
  left: 10px; 
  font-size: 14px; 
  background: white;
  padding: 0 5px; 
}


/* input height: 49px;(valor predeterminado) */
.custom-floating-label-input .ant-picker {

  width: 100%;
  margin-top: 19px;
  height: 56px;
}

.custom-floating-label-input input {
  width: 100%;
  height: 56px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  margin-top: 20px; 
}


.custom-floating-label-input .ant-select {
  width: 100%;
  margin-top: 19px;
  height: 56px;
}




.custom-floating-label-input-password label {
  position: absolute;
  top: 15%; 
  left: 10px; 
  font-size: 14px; 
  background: white; 
  padding: 0 5px; 
}
.custom-floating-label-input-password {
  width: 100%;
  height: 56px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  margin-top: 20px;
}

