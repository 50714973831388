:root {
    /* Definición de variables */
    --color-primary: #0885C7;
    --color-primary-light: #0885C740;
    --color-secondary: #FFCD00;
    --color-tertiary: #646363;
    --color-quaternary: #d9d9d9;
    --color-white: #ffffff;
    --color-black: #0f172a;
    --color-grey: #919EAB;
    --color-grey-light: #EBEBEB;
    --font-stack: Lato, sans-serif;
    --color-red: #FF0000;
    --color-login-backgound: #EAF8FF;
    --color-extra-green: #10CA00;
    --color-layout: #EAF8FF;
    --color-layout-executive: #FFFAE7;
    --color-form_light-background: #EAF8FFE5;  /* celeste claro al 80% */

    --title-size: 40px;
    --subtitle-size: 24px;
    --paragraph-size: 16px;
    --default-size: 18px;
    --other-size: 14px;
    --button-size: 21px;
    --input-size: 16px;
    --contrast: #FFFFFF;
    --other-contrast: #FFFFFF99;
    --button-weight: 800;
    --button-link-weight: 600;
    --primary-weight: 700;
    --secondary-weight: 400;
    --link-weight:500;

    --imagen-faro: url('../img/logo-faro.png');
} 


