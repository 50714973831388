@import url('variables.css');
@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* ESTILO GLOBAL*/
body {
  margin: 0;
  font-family: var(--font-stack);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: var(--font-stack);
}

/* ESTILO PARA TÍTULOS */
.titulo {
  font-family: var(--font-stack);
  font-size: var(--title-size);
  color: var(--color-primary);
  font-weight: var(--primary-weight);
}

/* ESTILO PARA TÍTULOS EN BLANCO */
.titulo_white {
  font-family: var(--font-stack);
  font-size: var(--title-size);
  color: var(--color-white);
  font-weight: var(--primary-weight);
}

/* ESTILO PARA TÍTULOS EN AZUL CLARO */
.titulo_blue_light {
  font-family: var(--font-stack);
  font-size: var(--title-size);
  color: var(--color-primary-light);
  font-weight: var(--primary-weight);
}

/* ESTILO PARA TÍTULOS EN NEGRO */
.titulo_black {
  font-family: var(--font-stack);
  font-size: var(--title-size);
  color: var(--color-black);
  font-weight: var(--primary-weight);
}


/* ESTILO PARA SUBTÍTULOS */
.subtitulo {
  font-family: var(--font-stack);
  font-size: var(--subtitle-size);
  color: var(--color-primary);
  font-weight: var(--primary-weight);
}
/* ESTILO PARA PÁRRAFOS*/
.parrafo {
  font-family: var(--font-stack);
  font-size: var(--paragraph-size);
  color: var(--color-tertiary);
  font-weight: var(--secondary-weight);
}

/* ESTILO PARA OTROS TIPOS DE TEXTOS EN GRIS*/
.texto_otros {
  font-family: var(--font-stack);
  font-size: var(--subtitle-size);
  color: var(--color-grey);
  font-weight: var(--secondary-weight);

}

/* ESTILO EXTRAS EN VERDE*/
.texto_extra {
  font-family: var(--font-stack);
  /* font-size: var(--paragraph-size); */
  font-size: 13px;
  color: var(--color-extra-green);
  font-weight: var(--secondary-weight);   /* 400 */
  text-align: left;
  line-height: 22px;
}

/* ESTILOS PARA BOTONES*/
/* Boton principal - Usos botones principales,acciones importantes.*/
.boton_primario {
  font-size: var(--subtitle-size); /*estaba en default*/
  background-color: var(--color-primary);
  color: var(--color-white);
  font-family: var(--font-stack);
  font-weight: var(--button-weight); /*700*/
  border-radius: 6px;
}

.boton_primario:hover {
  background-color: var(--color-white);
  color: var(--color-primary);
  border-radius: 6px;
}

.btn_medidas{
  width:536px;
  height:48px;
}

/* Boton secundario - Usos de selección, cambios de panel.*/
.boton_secundario {
  font-size: var(--default-size);
  background-color: var(--color-primary-light);
  color: var(--color-primary);
  font-family: var(--font-stack);
  font-weight: var(--button-weight);
  border-radius: 6px;
}

.boton_secundario:hover {
  background-color: var(--color-white);
  color: var(--color-primary);
  border-radius: 6px;
}

/* Boton enlace - Usos de botones de redirecciones, links, descarga de documentos.*/
/* NO ESTA BIEN CONFIGURADO */
.boton_secundario_link {
  font-size: var(--default-size);
  background-color: transparent;
  border: none;
  color: var(--color-primary);
  font-family: var(--font-stack);
  font-weight: var(--button-link-weight);
  text-decoration: underline;

}

.boton_secundario_link:hover {
  text-decoration: underline;
  background-color: none;
  border: none;
  background: none;
}

.boton_login {
  font-size: var(--button-size);
  background-color: var(--color-secondary);
  color: var(--color-black);
  font-family: var(--font-stack);
  font-weight: var(--button-weight);
  width: 400px;
  height: 65px;
  border-radius: 8px;
}

.boton_login:hover {
  background-color: var(--color-white);
  color: var(--color-primary);
  border-radius: 6px;
}

/* falta el hover del boton de google preguntar */
.boton_login_google {
  font-size: var(--button-size); /* se cambio por 21px todo los btn */
  background-color: var(--color-white);
  color: var(--color-black);
  font-family: var(--font-stack);
  font-weight: var(--button-weight);
  width: 400px;
  height: 65px;
  border-radius: 8px;
  /* nuevo margin */
  margin-top: -15px !important; 
}


.login_input{
  font-size: var(--input-size);
  font-family: var(--font-stack);
  font-weight: var(--button-weight);
  color: var(--color-grey);
  width: 400px;
  height: 56px;
  border-radius: 8px;
  border: 1px;
}

.icon_google_btn{
  text-align: left !important; 
  padding-right: 5px!important; 
  margin-bottom: 5px;
  margin-left: -5px;
  width: 33px;
  height: 33px;
}

.boton_login_link {
  font-family: var(--font-stack); 
  font-size: var(--other-size);
  font-weight:  var(--link-weighte);
  color: var(--color-white);;
  text-align: right;
  padding-right: 20px;
  margin-top: -7px;
}

.boton_login_link:hover {
  /* color: var(--color-grey); */
  color: var(--color-secondary);  /* hover amarillo */
}

.login_conteiner{
  background-color: var(--color-primary);
  color: var(--color-white);
  border-radius: 24px;
  /* padding: 2%; */
  /* padding: 3% 1% 1% 1%; */
  padding: 35px 2px 2px 2px;
  /* width: 500px;
  height: 553px; */
  width: 546px;
  height: 640px;
  /* margin: 5% auto 0 auto !important; */
  margin: 2% auto 0 auto !important;

  /* estos son los del diseño
  width: 546px;
  height: 640px;
  top: 280px;
  left: 447px;
  gap: 0px;
  border-radius: 24px 0px 0px 0px;
  opacity: 0px; */
  
}

.login_background{
    /* width: 100%; */
  height: 100vh;
  width: 100vw;
  text-align: center;
  background: var(--color-login-backgound);
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
}

/* solo del login */
.login_logotipo{
  padding-top: 5%;
}

/* Login Ejecutivo */
.login_executive_conteiner{
  background-color: var(--color-secondary);
  color: var(--color-white);
  border-radius: 24px;
  padding: 2%;
  /* width: 500px;
  height: 553px; */
  margin: 2% auto 0 auto !important;
  width: 546px;
  height: 516px;



}
.login_executive_background{
  height: 100vh;
  width: 100vw;
  text-align: center;
  background: rgba(255, 250, 231, 1);
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
}
.boton_executive_login {
  font-size: var(--default-size);
  background-color:var(--color-primary);
  color: var(--color-white);
  font-family: var(--font-stack);
  font-weight: var(--button-weight);
  width: 400px;
  height: 65px;
  border-radius: 8px;
}

.boton_executive_login:hover {
  background-color: var(--color-white);
  color: var(--color-primary);
  border-radius: 8px;
}


/* clientes y ejecutivo */
.fi_display{
  display: flex;
  justify-content: center;
}
/* clientes | margen entere btn y input*/
.margin_dt{
  margin-top: 10%;
}
/*ejecutivo | margen entere btn y input*/
.margin_dt_ex{
  margin-top: 25%;
}

/* formulario login clientes y ejecutivo */
/* style={{ marginTop: "10%", padding: "4%" }} */
.form_content{
  /* margin-top: 10%;
  padding: 4%; */
  margin-top: -15px;
  padding: 8%;
}

.form_content_executive{
    margin-top: 10%;
  padding: 4%;
}
/* contendor principal login clientes y ejecutivo  */
.contenedor_login{
  padding: 1% !important;
}
/* //Dashboard/ */

.dashboard_info_col{
  text-align: left;
  background: white;
  box-shadow: 0px 0px 5.2px 0px rgba(145, 158, 171, 0.24);
  border-radius: 26px;
  /* width: 574px; */
  height: 364px;
}
.dashboard_edit_col{
  /* margin-top: 35px !important; nuevo */
  margin-top: 95px !important; /*nuevo*/
}
.dashboard_info_box{
  text-align: left;
  /* padding: 25px; */
  padding: 35px;
  font-family: var(--font-stack);
}
.dashboard_info_sep{
  margin-bottom: 20px !important;
}

/* separacion arriba y bajo de check google */
.dashboard_check_sep{
  /* margin-top: 40px !important;
  margin-bottom: 45px !important; */
  /* margin-top: 125px !important; */
  margin-top: 15px !important; /* nuevo */
}

.dashboard_info_titulo{
  font-family: var(--font-stack);
  color: var(--color-primary);
  font-size: 24px;
  font-weight: 800;
  text-align: left;
}
.dashboard_info_texto{
  font-family: var(--font-stack);
  color: var(--color-grey);
  font-size: 15px;
  font-weight: 400;
  text-align: left;
}
.dashboard_info_link{
  color: var(--color-primary) ;
  font-family: var(--font-stack);
  font-weight: bold;
  font-size: var( --other-size);/*14px*/
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
}

.dashboard_info_link_bloqueo{
  color: var(--color-grey) ;
  font-family: var(--font-stack);
  font-weight: bold;
  font-size: var( --other-size);/*14px*/
  text-align: right;
  text-decoration: underline;
  cursor:default;
}


/* .ds_display_green_txt {
  display: block;
  margin-top: -20px !important;
  opacity: 1;

}

.ds_display_green_txt_hidden {
  display: block;
  margin-bottom: -20px;
  opacity: 0;

} */

.hidden-label {
  overflow: hidden;
  position: absolute;
  width: 1px;
}

/* texto en negrita */
.texto_bold{
  font-family: var(--font-stack);
  color: var(--color-black);
}

/* quitar luego de ajustes en mobile */
.carousel_col{
  text-align: right;
  /* box-shadow: 0px 0px 5.2px 0px rgba(145, 158, 171, 0.24);
  border-radius: 16px;
  background: red, */
}
/* //se los borre para hacer test */
.carousel_box{
  margin-left: 12%;
  margin-right: 12%;
}

.carousel_img{
  /* width: 574px;
  height: 364px;
  border-radius: 23px; */
  width: 100%;
  height: 364px;
  max-width: 100%;
  max-height: 100%;
  /* object-fit: cover; */
  border-radius: 23px;
}

/* //carusel arrow prev y next/ */
.custom-prev-arrow,
.custom-next-arrow {
  position: absolute;
  top: 35%;
  /* transform: translateY(-50%); */
  /* background: rgba(255, 255, 255, 0.5); */
  background: transparent;
  color: #000;
  border: none;
  outline: none;
  cursor: pointer;
  /* padding: 10px; */
  padding: 5px;
  margin: 40px;
  z-index: 2;
}

.custom-prev-arrow {
  /* position: fixed;
  left: 650px; */
  left: 10px;
}

.custom-next-arrow {
  /* position: fixed;
  right: 350px; */
  right: 10px;

}


/* ////testeado funciona pero las imagenes son muy grandes*/
.beneficios_col_test {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.beneficios_img_test {
  border: 2px solid #0885C7;
  border-radius: 16px;
}
.beneficios_img_test, .beneficios_icon_test {
  width: 150px; 
  height: 100px; 
  object-fit: cover; 
}

@media (max-width: 768px) {
  .beneficios_img_test, .beneficios_icon_test {
    width: 75px; 
    height: 50px; 
  }

  /* //nuevo verificar test*/
  .custom-prev-arrow {
    /* left: -35px; */
    left: -50px;
    top: 35px;
  }
  
  .custom-next-arrow {
    /* right: -35px; */
    right: -50px;
    top: 35px;
  }

  .carousel_box{
    /* estaba en 0% */
    margin-left:13%;
    margin-right: 13%;
  }
  .dashboard_edit_col{
    margin-top: 50px  !important;
  }
  .dashboard_info_link{
    text-align: center;
  }
  
  .dashboard_info_link_bloqueo{
    text-align: center;
  }
}

@media (max-width: 576px) {
  .beneficios_img_test, .beneficios_icon_test {
    width: 50px; 
    height: 33px; 
  }
}





.beneficios_col{
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: -60px;
}


.beneficios_img{
  width: 150px !important; 
  height: 100px !important; 
  margin-top: 15px;
}
.beneficios_icon{
  width: 49px !important; 
   height: 49px !important;  
   cursor: pointer !important;
    margin-top: 35px !important;
}


.mi_perfil_display{
  
}

.center_alg{
  text-align: center;
}

.left_alg{
  text-align: left;
}

.right_alg{
  text-align: right;
}

/* Layout css */
.layout_conteiner{
  background: var(--color-layout);
  z-index: 1;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  width: 100%;
  /* nuevo */
  padding: 12px;
}


.layout_executive_conteiner{
  background: var(--color-layout-executive);
  z-index: 1;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  width: 100%;
   /* nuevo */
  padding: 12px;
}

.row_layout{
  /* padding: 10px 20px 10px 10px; */
  padding: 1% 20px 10px 10px;
}

.layout_logotipo{
  width: 160px !important;
  max-width: 100% !important;
  min-width: 100px !important;
  margin-left: 30px !important;
}
.layout_img_perfil{
  width: 42px !important;
  max-width: 100% !important;
  min-width: 28px !important;
}
.layout_img_menu{
  width: 42px !important;
  max-width: 100% !important;
  min-width: 28px !important;
  cursor: pointer;
  
}

/* perfil */
.layout_text{
  font-family: var(--font-stack);
  font-size: var(--other-size);
  color: var(--color-primary);
  font-weight: var(--link-weigh);
  /* margin-right: -4px; */
  margin: 5px -4px 0 0;
}

/* testeando menu */
.xs{
  margin-top: 50px;
  display: inline;
  /* align-items: center;
  text-align: center;
  justify-content: center; */
  align-items: left;
  text-align: left;
  justify-content: left;

}

/* antigio menu textos link en blanco */
.menu_links{
  font-size: var(--default-size);
  color: var(--color-white) !important;
  font-family: var(--font-stack);
}
/* nuevo links en negro */
.menu_text{
  color: var(--color-black) !important;
  font-family: var(--font-stack);
  font-weight: var(--link-weigh);
  font-size: 20px;

}

.menu_executive_links{
  font-size: var(--default-size);
  color: var(--color-black) !important;
  font-family: var(--font-stack);
}


.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  border-bottom: none !important;
  font-family: var(--font-stack) !important;
  color:  var(--color-black)!important;
  font-size:   15px !important;
  font-weight: 400 !important;
}

.ant-table-thead > tr > th {
  border-top: none !important;
  font-family: var(--font-stack) !important;
  font-size:   16px !important;
  color:  var(--color-primary) !important;
  font-weight: 700 !important;
  background-color: var(--color-white) !important;
}

.ant-table-container {
  /* border: none !important;
  box-shadow: 0px 0px 30.4px -4px rgba(145, 158, 171, 0.24); */
  border: none !important;
  border-radius: 26px;
  overflow: hidden;
  box-shadow: 0px 0px 30.4px -4px rgba(145, 158, 171, 0.24);

}

.ant-table-cell {
  border-right: none !important;
}

.ant-table-bordered .ant-table-container {

  border: none !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 
}



/* MENU */
.ant-drawer
.ant-drawer-content{
  border-radius: 16px 16px;
  background: white;
  
}
.ant-drawer-content-wrapper{
  width: 320px;
  height: 500px;
  border-radius: 16px;
  margin-top: 118px;
  margin-right: 18px;

}
.ant-drawer-header{
  padding-top: 0%;

  
}
.ant-drawer-footer{
  background: #F2F4F6;
}
.ant-drawer-body{
  background: white;
  height: 200px;
  border-radius: 0 0 16px 16px;
  
}
.ant-menu{
  background: white;
}

/* test de upload */
.file-input {
  background-color: #f0f0f0; /* Color de fondo gris */
  border: 1px solid #d9d9d9; /* Borde ligeramente más oscuro que el fondo */
  padding: 4px 8px; /* Algunos paddings para mejor estética */
  display: flex;
  align-items: center; /* Centrar el icono y el texto verticalmente */
  cursor: pointer; /* Cursor en forma de mano para indicar que es clickeable */
  border-radius: 13px;
}

.file-input input[type="file"] {
  opacity: 0; /* Oculta el input real */
  position: absolute;
  z-index: -1; /* Mantén el input por detrás para poder hacer clic en el texto e icono */
}

.file-input-icon {
  margin-right: 8px; /* Espacio entre el icono y el texto */
  
}
.button-separate-benefist {
  display: flex;
  justify-content: space-between;
}


/* ///crear cuenta style */
.contenedor_account{
  padding: 1% !important;
}
.account_form_conteiner{
  background-color: var(--color-form_light-background);
  /* color gris textos*/
  color: var(--color-grey); 
  border-radius: 24px;
  width: 546px;
  height: 640px;
  margin: 2% auto 0 auto !important;
  padding: 35px 2px 2px 2px !important;
  /* text-align: center; */

  /* width: 546px;
height: 694px; */
}

.form_account_content{
  margin-top: -15px;
  padding: 8%;
}

.account_input{
  font-size: var(--input-size);
  font-family: var(--font-stack);
  font-weight: var(--button-weight);
  width: 425px;
  height: 56px;
  border-radius: 8px;
  border: 1px;
}

.boton_account{
  font-size: var(--button-size);
  background-color: var(--color-secondary);
  color: var(--color-black);
  font-family: var(--font-stack);
  font-weight: var(--button-weight);
  width: 425px;
  height: 65px;
  border-radius: 8px;
}
.account_text{
  padding: 25px 58px 0 58px !important;
  font-size: var(--other-size);
  color: var(--color-grey);
  font-family: var(--font-stack);
  font-weight: var(--secondary-weight);
}
.account_leyend{
  font-size: var(--other-size);
  color: var(--color-grey);
  font-family: var(--font-stack);
  font-weight: var(--secondary-weight);
  text-align: left;
}
.account_icon_pass .ant-input-suffix .anticon {
  color: #FFFFFF99;
}

/* CAMBIAR CONTRASEÑA */
.contenedor_changepass{
  padding: 0;
  margin: 0 auto;
}
.changepass_form_conteiner{
  background-color: var(--color-form_light-background);
  color: var(--color-grey); 
  border-radius: 24px;
  width: 546px;
  height: 640px;
  margin: 1% auto 0 auto !important;
  padding: 35px 2px 2px 2px !important;
}
.changepass_form_content{
  padding: 8%;
}
.changepass_input{
  font-size: var(--input-size);
  font-family: var(--font-stack);
  font-weight: var(--button-weight);
  width: 425px;
  height: 56px;
  border-radius: 8px;
  border: 1px;
}
.changepass_leyend{
  font-size: var(--other-size);
  color: var(--color-grey);
  font-family: var(--font-stack);
  font-weight: var(--secondary-weight);
  text-align: left;
}
.changepass_boton{
  font-size: var(--button-size);
  background-color: var(--color-secondary);
  color: var(--color-black);
  font-family: var(--font-stack);
  font-weight: var(--button-weight);
  width: 425px;
  height: 65px;
  border-radius: 8px;
}
.changepass_text{
  padding: 25px 58px 0 58px !important;
  font-size: var(--other-size);
  color: var(--color-grey);
  font-family: var(--font-stack);
  font-weight: var(--secondary-weight);
}

/* RECUPERAR CONTRASEÑA */
.recover_form_conteiner{
  width: 546px;
  height: 575px;
  border-radius: 24px;
  font-family: var(--font-stack);
  background-color: var(--color-form_light-background);

  color: var(--color-grey); 
  margin: 1% auto 0 auto !important;
  padding: 35px 1px 1px 1px !important;
}
.recover_input{
  font-size: var(--input-size);
  font-family: var(--font-stack);
  font-weight: var(--button-weight);
  width: 445px;
  height: 56px;
  border-radius: 8px;
  border: 1px;
}

.boton_recover{
  font-size: var(--button-size);
  background-color: var(--color-secondary);
  color: var(--color-black);
  font-family: var(--font-stack);
  font-weight: var(--button-weight);
  width: 445px;
  height: 65px;
  border-radius: 8px;
  text-align: center;
}

.boton_change_password{
  font-size: var(--button-size);
  background-color: var(--color-secondary);
  color: var(--color-black);
  font-family: var(--font-stack);
  font-weight: var(--button-weight);
  width: 100%;
  height: 48px;
  border-radius: 8px;
  text-align: center;
  margin-top: 5px;

}


/* PANTALLA ERROR 404 NOT FOUNT */
.contenedor-error {
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat; 
  text-align: center; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.error_img_view {
  text-align: center; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 900px !important;
}
.error_text{
  font-family: var(--font-stack);
  font-weight: var(--button-weight);
  color: var(--color-primary);
  font-size: 165px;
  font-weight: 800;
  line-height: 121px;
  text-align: center;
}
.error_subtext{
  font-family: var(--font-stack);
  font-weight: var(--secondary-weight);
  color: var(--color-black);
  font-size: 38px;
  font-weight: 500;
  line-height: 69px;
  text-align: center;
}

.error_btn{
  font-size: 31px;
  background-color: var(--color-secondary);
  font-family: var(--font-stack);
  font-weight: var(--button-weight);
  color: var(--color-black);
  width: 472px;
  height: 65px;
  border-radius: 8px;
  text-align: center;
  margin-top: 3%;
}

.error_text_contenedor{
  position: absolute;
  text-align: center;
  /* padding: 27%; */
  padding-top: 30%;
}


.test-embed{
  width: 100%;
  height: 150px;
  object-fit: contain;
  
}

@media (max-width: 768px) {

  .login_conteiner{
    width:340px;
    height: 560px;
  }

  .login_executive_conteiner{
    width:340px;
    height: 490px;
    margin: 10% auto 0 auto !important;
  }
  .mi_perfil_display{
    display: none !important;
  }
  .boton_login{
    width: 280px;
  }
  .boton_login_google{
    width: 280px;
  }
  .login_input{
    width: 280px;
  }

  .boton_login_link {
    padding-right: 10px;
  }
  .boton_executive_login {
    width: 280px;
  }

  .layout_logotipo{
    margin-left: 0 !important;
  }

  .form_content{
    padding: 4%;
  }
  .dashboard_info_col{
    height: auto; /*nuevo*/
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  /* .carousel_col{
    padding-top: 30px;
  } */

  .carousel_img{
    /* width: auto;
    height: auto; */
    /* height: 364px; */
    height: 200px; 
  }


  .account_form_conteiner{
    width: 330px;
    height: auto;
    margin: 0;
    padding: 0;
  }

  .account_input{
    width: 260px;
  }

  .boton_account{
    width: 260px;
  }
  .account_text{
    display: none;
  }

  .changepass_form_conteiner{
    width: 330px;
    height: auto;
    margin: 0;
    padding: 0;
  }
  .changepass_input{
    width: 260px;
  }
  .changepass_boton{
    width: 260px;
  }
  .changepass_text{
    display: none;
  }
  /* PANTALLA ERROR 404 NOT FOUNT */
  .error_text_contenedor{
    padding-top: 100%;
  }
  .error_img_view {
    width: 100vw !important;
    height: 100vh !important;
  }
  .error_text{
    font-size: 90px  !important;
    /* margin-top: 70%  !important; */
    margin-bottom: -10%  !important;
  }
  .error_subtext{
    font-size: 20px !important;
  }
  
  .error_btn{
    width: 350px !important;
    margin-top: 5%  !important;
  }
  

/* /// */
.btn_medidas{
  width: 340px;
}
  
}

/* TABLET */
@media (min-width: 768px) and (max-width: 1024px) {

  /* /// */
  .login_conteiner{
    width: 546px;
  }
  /* modificar */
  /* .boton_login{
    width: 280px;
  } */
  /* .login_executive_conteiner{
    width: 546px;
  } */
  /* modificar */
  /* .boton_executive_login {
    width: 280px;
  } */

  .login_executive_conteiner{
    width: 546px;
    height: 516px;
  }

  .dashboard_info_col{
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
 
  .carousel_img{
    width: auto;
    height: 364px;
  }

  /* PANTALLA ERROR 404 NOT FOUNT */
  .error_text_contenedor{
    padding-top: 62%;
  }
  .error_img_view {
    width: 100vw !important;
    height: 100vh !important;
  }
  .error_text{
    font-size: 165px;
    /* margin-top: 30%; */
    margin-bottom: 1%  !important;

  }
  .error_subtext{
    font-size: 38px;
  
  }
  .error_btn{
    width: 472px;
    margin-top: 65px;
  }

  .btn_medidas{
    width:536px;

  }

}

/* TABLET Horizontal*/
@media (min-width: 1024px) and (max-width: 1279px) {

}


@media (min-width: 1850px) {

  .beneficios_col{
    margin-right: -110px;
  }

  /* PANTALLA ERROR 404 NOT FOUNT */
  .error_text_contenedor{
    padding-top: 28%;
  }
  .error_btn{
    margin-top: 25px;
  } 

}

@media (max-width: 1920px) {
  /* .error_text_contenedor{
    padding-top: 28%;
  }
  .error_btn{
    margin-top: 25px;
  } */
}
