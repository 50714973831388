@import url('variables.css');
@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


.col-floating{
  margin-bottom: 20px;
}


.floating-label-form-item {
  font-family: var(--font-stack);
  position: relative;
}
.floating-label-form-item .ant-form-item-required {
  font-family: var(--font-stack);
  color: #919EAB !important;
  font-size: 14px !important;

}

/*test css*/
.floating-label-form-item .ant-form-item-label  {
  /* position: absolute;
  background: red;
  z-index:1;
  bottom: calc(68% - 3px);

  margin-left: 15px;
  padding-left: 10px; */


  /* bottom: calc(15% - 1px);
  left: 0; */
  /* top: 18px; */

  /* margin-left: 12px;
  transform: translateY(-100%);
  transform-origin: bottom left;
  transition: all 0.3s;
  z-index: 3;
  pointer-events: none;
  background: white; */
  /* padding-left: 8px; */
  /* padding: 0 0 8px 5px; */

}

.floating-label-form-item .ant-form-item-label {
  position: absolute;
  left: 15px; 
  top: 1%; 
  transform: translateY(-50%);
  background-color: white;
  padding: 0 5px 0 10px; 
  z-index: 1; 
  
}

.floating-label-form-item .ant-input {

  padding-top: 20px;

}

.floating-label-form-item .ant-form-item-explain-error {
  
  /* margin-bottom: 30px; */
   /* Agrega espacio adicional entre el input y el mensaje de error */
/* Altura fija para el contenedor del mensaje de error */
   /* height: 50px; 
   transition: height 0.3s ease-in-out;
   overflow: hidden;  */

  
}

/* bueno descomentar */
.floating-label-form-item .ant-form-item-explain-error {

/* 
  height: auto; 
  margin-top: 5px;  
  margin-bottom: 20px; */


  /* Transición suave para cambiar la altura */
  /* transition: height 0.3s ease-in-out; */


}


.floating-label-form-item  .ant-select {
  width: 100%;
  /* margin-top: 19px; */
  height: 56px;
}
.floating-label-form-item .ant-picker {
  width: 100%;
  /* margin-top: 19px; */
  height: 56px;
}

.floating-label-form-item .ant-form-item-control-input-content {
  position: relative;

}

.floating-label-form-item input,
.floating-label-form-item .ant-select-selector {
  padding: 15px;
}



/*css para la seccion del radio button*/
.floating-label-radio-form-item .ant-form-item-label {
  display: none;
}
.floating-label-radio-form-item .ant-radio-group {
  margin-top: 15px;
  margin-left:5px;


}
.floating-label-radio-form-item .ant-radio-inner{
  background-color: white; /*cambia el color sin el check*/
  width: 26px;
  height: 26px;
}
.floating-label-radio-form-item .ant-radio-inner::after{

  background: #0885C7;
  border-radius: 50%;
  /* width: 80px;
  height: 80px; */
  padding: 25px;
  margin: -25px -25px 0 -25px;
}
.floating-label-radio-form-item .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: white;
  border: 2px solid #0885C7;
}


.ant-upload-drag-icon,
.ant-upload-text,
.ant-upload-hint {
  font-family: var(--font-stack);
}
