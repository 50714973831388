@import url('variables.css');


.modal_conteiner .ant-modal-content{
    text-align: center;
    border-radius: 18px !important;
    height: auto  !important;
    padding: 5%;
}
.modal_titulo{
    font-family: var(--font-stack);
    font-size: 43px;
    font-weight: 700;
    color: var(--color-black);
    text-align: center;
    padding-bottom: 2%;
    padding-top: 5%;
    
}
.modal_subtitulo{
    font-family:  var(--font-stack);
    font-size: 25px;
    font-weight: 500;
    color: var(--color-black);
    text-align: center;
    margin-bottom: 20px;
}
.modal_img{
    width: 93px !important;
    height: 92px !important;
    padding:10px !important;;
}
.modal_btn{
    font-size: var(--subtitle-size);
    font-family: var(--font-stack);
    font-weight: var(--button-weight); /*700*/
    background-color: var(--color-primary);
    color: var(--color-white);
    display: block;
    margin: auto;
    /* width: 355px; */
    width: 100%;
    max-width: 100%;
    height: 60px;
    border-radius: 8px;
}

.modal_btn:hover {
    background-color: var(--color-white);
    color: var(--color-primary);
    border-radius: 6px;
}

.modal_input{
    font-size: var(--input-size);
    font-family: var(--font-stack);
    font-weight: var(--button-weight);
    height: 56px;
    border-radius: 8px;
    border: 1px solid var(--color-grey);
}

.modal_input_confirm{
    font-size: var(--input-size);
    font-family: var(--font-stack);
    font-weight: var(--button-weight);
    height: 56px;
    border-radius: 8px;
    border: 1px solid var(--color-grey);
    width: 100%;
}

/* CONFIRMAR */
.modal_btn_confirm{
    font-size: var(--subtitle-size);
    font-family: var(--font-stack);
    font-weight: var(--button-weight); /*700*/
    background-color: var(--color-extra-green);
    color: var(--color-white);
    height: 50px;
    border-radius: 8px;
    margin-right: 8px;
}

.modal_btn_confirm:hover {
    background-color: var(--color-white);
    color: var(--color-primary);
    border-radius: 6px;
    border-color: var(--color-primary);
}

/* CANCELAR */
.modal_btn_cancel{
    font-size: var(--subtitle-size);
    font-family: var(--font-stack);
    font-weight: var(--button-weight); /*700*/
    background-color: var(--color-red);
    color: var(--color-white);
    height: 50px;
    border-radius: 8px;

}

.modal_btn_cancel:hover {
    background-color: var(--color-white);
    color: var(--color-primary);
    border-radius: 6px;
    border-color: var(--color-primary);
}


@media (max-width: 768px) {
    .modal_conteiner .ant-modal-content{
        width: auto !important;
        height: auto !important;
    }
    /* .modal_btn{
        width: auto !important;
        margin: auto !important;
    } */
}

@media (min-width: 768px) and (max-width: 1024px) {
    .modal_conteiner .ant-modal-content{
        width: auto !important;
        height: auto !important;
    }
    /* .modal_btn{
        width: auto !important;
        margin: auto !important;
    } */
}